.iconPay{
    font-size: 8px;
}
.iconOpt{
    font-size: 14px;
}



.btnDetalle{
    margin-right: 10px;
    padding: 0 5px 0 5px;

}
.modal-familia{
    position: fixed; 
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%); 
    width: 600px;
    background-color: #111827;
    height: 450px;
    padding: 50px;
}
.thnombre{
margin-left: 50px;
width: auto;
}

.thedad{
    width: 100px;
    align-items: center;
    text-align: center;
}

.thcertificado{
    align-items: center;
    text-align: center;
    font-size: 12px;
}

.btnmodalclose{
    position: absolute;
    bottom: 55px;
    left: 30px;
    font-size: 18px;
    cursor: pointer;
    background-color: transparent; /* Cambia el color de fondo a transparente */
    border: 1px solid #fff; /* Agrega un borde */
    padding: 5px 12px;
    border-radius: 5px;
    color: #47a5fa;
}

.tbmodal{
    color: #fff;
    
}

.tbmodal thead{
    background-color: #313743 ;
    color: #fff;
    text-align: center;
    
}

.tbmodal tbody{
    font-size: 14px;
    font-weight: 100;
}

.containerButton{

    display: flex;
    justify-content: right;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 30px;

    button{
        background-color: #111827; 
        border: 1px solid #47a5fa;
        padding: 5px 12px;
        border-radius: 5px;
        color: #fff;
    }
}
