#tbsolicitud{
    thead{
        tr{
            th{
                background-color: #111827;
                color: white;
            }
        }
    }

    tbody{
        tr:hover{
            background-color: #313743;
            color: white;
            text-align: center;

        }
        tr{
            text-align: center;

        }

    }
}